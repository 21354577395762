/*
 * IMPORT
 */
import React, { Suspense, useEffect } from "react"; // NPM: React package.
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom"; // NPM: React Router Dom for routing.
import { connect } from "react-redux"; // NPM: React Redux for state managment.
import { Flex, Spinner } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast"; // NPM: React Hot Toast for toast messages.
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
/*
* COMPONENTS
*/
import ScrollToTop from './components/scrollToTop/index';

/* 
* QUERY
*/
import AccountTokenExpireVerificationQuery from './views/auth/logout/__query__/AccountTokenExpireVerification.index';

/* 
* MUTATION  
*/
import AccountLogoutMutation from './views/auth/logout/__mutation__/AccountLogout.mutation.index';

/*
* AUTH
*/
import Context from "./context";
const SignUp = React.lazy(() => import("./views/auth/signUp/SignUpDefault.js"));
const NewLayout = React.lazy(() => import("layouts/admin"));
const SignIn = React.lazy(() => import("views/auth/signIn/SignInDefault.js"));
const ForgotPassword = React.lazy(() => import("views/auth/forgotPassword/index.js"));
const AccountSettings = React.lazy(() => import("./views/admin/settings/index.js"));

// TOOL Components
const DashboardsDefault = React.lazy(() => import("./views/admin/masterview/index"));
const PaidAds = React.lazy(() => import("./views/admin/paidAds/index"));
const GoogleAds = React.lazy(() => import("./views/admin/googleAds/index"));
const FacebookAds = React.lazy(() => import("./views/admin/facebookAds/index"));
const Seo = React.lazy(() => import("./views/admin/seo/index"));
const Social = React.lazy(() => import('./views/admin/social/index'));
const AdvanceAnalytics = React.lazy(() => import("./views/admin/analytics/index"));
const AnalyticsExplore = React.lazy(() => import("./views/admin/analyticsExplore/index"));
const AdFraud = React.lazy(() => import('./views/admin/adFraud/index'));

// WEBSITE Components
const LandingPage = React.lazy(() => import("./views/admin/website/LandingPage/index"));
const IntegrationPage = React.lazy(() => import("./views/auth/onBoard/integration/index"));
const PrivacyPolicy = React.lazy(() => import("./views/admin/website/privacyPolicy/index"));
const AccountSelect = React.lazy(() => import("./views/auth/onBoard/integration/selectAccount"));
const whatsNext = React.lazy(() => import("./views/auth/onBoard/integration/whatsNext"));
const Gmb = React.lazy(() => import('./views/admin/gmb/index'));
const NotFound = React.lazy(() => import('./views/admin/website/notFound/index'));
const AboutUs = React.lazy(() => import('./views/admin/website/aboutUs/index'));
const FeaturesPage = React.lazy(() => import('./views/admin/website/features/index'));
const IntegrationPageWebsite = React.lazy(() => import('./views/admin/website/integration/index'));
const ContactUsPage = React.lazy(() => import('./views/admin/website/contactUs/index'));
const PricingPage = React.lazy(() => import('./views/admin/website/pricing/index'));
const CaseStudy = React.lazy(() => import('./views/admin/website/caseStudy/index'));
const TermsAndConditions = React.lazy(() => import('./views/admin/website/termsAndConditions/index'));
const ThankYouPage = React.lazy(() => import('./views/admin/website/thankYouPage/index'));
const AgencyOrIndividualDecider = React.lazy(() => import('./views/auth/onBoard/decider/index'));
const IndividualJourney = React.lazy(() => import('./views/auth/onBoard/journeyIndividual/index'));
const AgencyJourney = React.lazy(() => import('./views/auth/onBoard/journeyAgency/index'));
const AddCustomer = React.lazy(() => import('./views/auth/onBoard/addCustomer/index'));

/*
 * OBJECT
 */
const Index = ({ account, ClearConnector, ClearLocal, ClearDateRange, AccountUpdate, ClearExplore }) => {

  const { selectedCustomer } = account;

  // LOCOUT MUTATION
  const [MutationAccountLogout,{ loading: AccountLogoutMutationLoading }] = useMutation(AccountLogoutMutation, {
    context: { headers: { "l-authorization": account.token }}
  });

  // ACCOUNT TOKEN VERIFICATION QUERY
  const [QueryAccountTokenExpireVerification, { loading: AccountTokenExpireVerificationLoading }] = useLazyQuery(AccountTokenExpireVerificationQuery, {
    context: { headers: { "l-authorization": account?.token } },
  });
  
  const temp = async () => {
    try {
      const { data } = await QueryAccountTokenExpireVerification(); // Await the query execution
  
      console.log(data, account?.isUserLoggedIn, "AccountTokenExpireVerificationQueryLoading");
  
      if (data?.AccountVerification?.status === "TOKEN_VERIFIED") {
        console.log("tokenVerified, hello");
      } else if (data?.AccountVerification?.status === "TOKEN_EXPIRED") {
        console.log("tokenExpired, hello");
  
        if (account?.isUserLoggedIn) {
          _HandleLogout();
        }
      }
    } catch (error) {
      console.error("Error verifying token:", error);
    }
  };
  
  useEffect(() => {
    if (account?.isUserLoggedIn) {
      temp(); // Call temp() asynchronously inside useEffect
    }
  }, [account?.isUserLoggedIn]);
  
  
  
  async function _HandleLogout() {
    const { data } = await MutationAccountLogout();

    if (data?.AccountLogout?.status === "LOGOUT_SUCCESSFUL") {

      console.log("loggingOut, hello")
      // Clear local storage
      localStorage.clear();

      // Clear session storage
      sessionStorage.clear();
      
      AccountUpdate({ isUserLoggedIn: false, step: "NONE", })
      ClearDateRange(); // clear the date range store
      ClearLocal(); // clear the local store
      ClearConnector(); // clear the connector store
      ClearExplore(); // clear the explore store
      // window.location.reload(); // Reload the page

    }else {
      console.log("Cannot log out! Please try again");
    }
  }

  // Const Assignment
  const _routes = [
    ["/masterview", DashboardsDefault, 1, true],
    ["/setting/:code", AccountSettings, 2, true],
    ["/setting", AccountSettings, 3, true],
    ["/analytics-advance", AdvanceAnalytics, 4, true],
    ["/paid-ads", PaidAds, 5, true],
    ["/paid-google-ads", GoogleAds, 6, true],
    ["/paid-facebook-ads", FacebookAds, 7, true],
    ["/organic-seo", Seo, 8, true],
    ["/analytics-explore", AnalyticsExplore, 9, true],
    ["/organic-google-business-profile", Gmb, 10, true],
    ["/organic-social", Social, 11, true],
    ["/ad-fraud", AdFraud, 12, true],
  ];
  const [context, setContext] = React.useState({});

  return (
    <Context.Provider value={{ context, setContext }}>
      <Toaster />
      <Router>
        <ScrollToTop />
        <Suspense fallback={<Flex w='100vw' h='100vh'><Spinner color="#FFDEC7" mx='auto' my='auto' /></Flex>}>
        <Switch>

           {/* WEBSITE ROUTES */}
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/features" component={FeaturesPage} />
          <Route exact path="/integrations" component={IntegrationPageWebsite} />
          <Route exact path="/contact-us" component={ContactUsPage} />
          <Route exact path="/pricing" component={PricingPage} />
          <Route exact path="/case-studies" component={CaseStudy} />
          <Route exact path="/book-demo/thank-you" component={ThankYouPage} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditions} />

          {/* IF USER IS NOT LOGGED IN */}
          {account?.step === "NONE" &&
            <Switch>
               <Route exact path="/signup" component={SignUp} />
               <Route exact path="/signin" component={SignIn} />
               <Route exact path="/forget-password" component={ForgotPassword} />

                {/* Catch-all Redirect for any other route */}
               <Redirect to="/signin" />
            </Switch>
          }

          {/* STEP ONE */}
          {account?.step === "STEP_ONE" &&
            <Switch>
              <Route exact path="/on-boarding" component={AgencyOrIndividualDecider} />
              <Route exact path="/on-boarding/individual" component={IndividualJourney} />
              <Route exact path="/on-boarding/agency" component={AgencyJourney} />

               {/* Catch-all Redirect for any other route */}
              <Redirect to="/on-boarding" />
              <Redirect from="/signin" to='on-boarding' />
              <Redirect from="/signup" to='on-boarding' />
            </Switch>
          }

          {/* STEP TWO */}
          {account?.step === "STEP_TWO" &&
            <Switch>
               <Route exact from="/on-boarding/integration/whats-next" component={whatsNext}/>
               <Route exact from="/on-boarding/integration/select-account" component={AccountSelect} />
               <Route exact from="/on-boarding/integration" component={IntegrationPage}/>
               <Route exact from="/add-customer" component={AddCustomer} />
               <Route exact from="/on-boarding/integration/whats-next" component={whatsNext}/>
               <Route exact from="/on-boarding/integration/select-account" component={AccountSelect} />
               <Route exact from="/on-boarding/integration" component={IntegrationPage}/>
               {_routes.map((i) => (
                 <Route
                   exact
                   path={i[0]}
                   render={(props) => { const Component = i[1]; return ( <NewLayout> <Component {...props} /> </NewLayout>);}}
                   key={i[2]}
                 />
               ))}

               {/* Catch-all Redirect for any other route */}
               <Redirect exact from="/on-boarding" to="/masterview" />
               <Redirect exact from="/on-boarding/individual" to="/masterview" />
               <Redirect exact from="/on-boarding/agency" to="/add-customer" />
               <Redirect from="/signin" to='masterview' />
               <Redirect from="/signup" to='masterview' />
               <Redirect to="/signin" />
            </Switch>
          }

           {/* IF NO ROUTE IS AVAILABLE SHOW 404 PAGE */}
          <Route component={NotFound} />

        </Switch>
        </Suspense>
      </Router>
    </Context.Provider>
  );
};

/*
* REDUX
*/
const _MapStateToProps = (__state) => ({ account: __state.Account });
const _MapDispatchToProps = (__dispatch) => ({
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  ClearDateRange: () => __dispatch({ type: "CLEAR_DATE_RANGE" }),
  ClearLocal: (l) => __dispatch({ type: "LOCAL_CLEAR" }),
  ClearConnector: (l) => __dispatch({ type: "CLEAR_CONNECTOR" }),
  ClearExplore: (l) => __dispatch({ type: "EXPLORE_CLEAR" }),
});


/*
* EXPORT
*/
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
